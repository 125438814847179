 <template>
  <!-- 头部带返回导航风格组件 -->
  <div class="liefeng-mainpage-params">
    <Form :label-width="150" ref="bannerForm">
        <FormItem label="左边图标">
        <Input
          v-model.trim="data.leftIconType"
          placeholder="从图标样式集合中获得"
        ></Input>
      </FormItem>
             <FormItem label="改变左边按钮事件">
        <RadioGroup v-model="data.changeLeftClick">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否（默认返回上一级）</Radio>
        </RadioGroup>
      </FormItem>
        <FormItem label="改变左边按钮地址"   v-if="data.changeLeftClick">
        <Input
          v-model.trim="data.onClickLeftIconUrl"
          placeholder="支持本地地址及网址"
        ></Input>
      </FormItem>
    <FormItem label="从右到左第一个图标">
        <Input
          v-model.trim="data.rightFirstIconType"
          placeholder="从图标样式集合中获得"
        ></Input>
      </FormItem>
       <FormItem label="右边第一个按钮事件">
        <RadioGroup v-model="data.rightFirstEvent">
          <Radio :label="0">跳转地址</Radio>
          <Radio :label="1">接口,完成返上页</Radio>
          <Radio :label="2">接口,完成刷新</Radio>
        </RadioGroup>
      </FormItem>
        <FormItem label="右一按钮接口类型" v-if="data.rightFirstEvent==1||data.rightFirstEvent==2">
        <RadioGroup v-model="data.rightFirstEventType">
          <Radio label="post">Post接口</Radio>
          <Radio label="get">Get接口</Radio>
        </RadioGroup>
      </FormItem>
       <FormItem label="右一按钮执行前询问">
        <Input
          v-model.trim="data.onClickRgIconStConfirm"
          placeholder="若不需要询问则不填写"
        ></Input>
      </FormItem>
        <FormItem label="右边第一个按钮地址">
        <Input
          v-model.trim="data.onClickRgIconStUrl"
          placeholder="从右到左第一个按钮跳转地址"
        ></Input>
      </FormItem>
          <FormItem label="从右到左第二个图标">
        <Input
          v-model.trim="data.rightSecondIconType"
          placeholder="从图标样式集合中获得"
        ></Input>
      </FormItem>
          <FormItem label="右边第二个按钮事件">
        <RadioGroup v-model="data.rightSecondEvent">
          <Radio :label="0">跳转地址</Radio>
          <Radio :label="1">接口,完成返上页</Radio>
          <Radio :label="2">接口,完成刷新</Radio>
        </RadioGroup>
          </FormItem>
            <FormItem label="右二按钮接口类型"  v-if="data.rightSecondEvent==1||data.rightSecondEvent==2">
        <RadioGroup v-model="data.rightSecondEventType">
          <Radio label="post">Post接口</Radio>
          <Radio label="get">Get接口</Radio>
        </RadioGroup>
      </FormItem>
       <FormItem label="右二按钮执行前询问">
        <Input
          v-model.trim="data.onClickRgIconNdConfirm"
          placeholder="若不需要询问则不填写"
        ></Input>
      </FormItem>
         <FormItem label="右边第二个按钮地址">
        <Input
          v-model.trim="data.onClickRgIconNdUrl"
          placeholder="从右到左第二个按钮跳转地址"
        ></Input>
      </FormItem>
        <Button
          style="margin: 5px 40px;"
          type="info"
          @click="winopen"
          >查看图标样式集合</Button
        >
        <div  style="margin: 5px 40px;">查看图标样式集合，选择“Font Class”选项卡，复制对应图标的样式（不包含“.”）；以“liefeng-icon”开头</div>
    </Form>
  </div>
</template>

<script>
/**
 * import Navbar from "./components/Navbar";
 * 头部带返回导航风格
 */
export default {
  components: {},
  props: {
    data: {
      //组件数据，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
    target: {
      //组件对象，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
    };
  },
  created() {},
  methods: {
    winopen(){
       window.open('/fontlibs/demo_index.html');
    }
  },
};
</script>
<style lang="less">
.liefeng-mainpage-params {
  width: 100%;
  margin: 5px 0;
}
</style>